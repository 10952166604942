
import { 
    apiEngineList, 
    apiSettingsExpressTemuEdit, 
    apiSettingsExpressTemuAdd, 
    apiSettingsExpressTemuDetail, 
    apiSettingsExpressTemuEngineList 
    } from '@/api/setting'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PageMode } from '@/utils/type'

@Component
export default class LogisticsEdit extends Vue {
    form: any = {
        id: 0,
        title: '',
        remark: '',
        customer_id: '',
        api_token: '',
        data: {}
    }
    formData: any = []
    engineList: any = []
    temuEngineList: any = []
    type = ''
    mode = 'add'

    handleSave() {
        let params: any = {}
        if (this.mode == 'edit') {
            apiSettingsExpressTemuEdit({
                id: this.form.id,
                code: this.form.code,
                temu_id: this.form.temu_id
            }).then(res => {
                this.$message.success('编辑成功')
            })
        } else {
            apiSettingsExpressTemuAdd({
                code: this.form.code,
                temu_id: this.form.temu_id
            }).then(res => {
                this.$message.success('新增成功')
            })
        }
        // apiSetLogisticsConfig(this.form)
    }

    changeType() { }

    getEngineList() {
        apiEngineList({}).then(res => {
            return new Promise((resolve) => {
                let keys = Object.keys(res)
                for (let i of keys) {
                    this.engineList.push({ value: i, label: res[i] })
                }
                resolve(res)
            })
        })
    }
    getTemuEngineList() {
        apiSettingsExpressTemuEngineList({}).then(res => {
            return new Promise((resolve) => {
                if(res) {
                    res.forEach((v:any)=>{
                        v.id = String(v.id)
                    })
                }
                this.temuEngineList = res
                resolve(res)
            })
        })
    }
    async created() {
        const query: any = this.$route.query
        if (query.mode) this.mode = query.mode

        await this.getEngineList()
        await this.getTemuEngineList()

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            console.log(this.form.id)
            apiSettingsExpressTemuDetail({ id: this.form.id }).then(res => {
                this.form = { ...res, ...res.data }
            })
        }
    }
}
